/* Variables */
:root {
  --orange: #ef5472;
  --pink: #ef5472;
  --purple: #30318b;
}

body {
  background: #f8f8f8;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

.ant-table-body {
  overflow-x: auto;
}

.p8 > .ant-card-body {
  padding: 4px !important;
}

.ant-tabs-nav {
  background-color: #fff;
}

.ant-tabs-nav-list {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-tabs {
  width: 100%;
}

.ant-table-content {
  overflow-x: auto;
}

.fixed-content-layout {
  overflow-y: auto !important;
}
