.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input.remove-margin {
  margin-bottom: 0;
}

.input__label {
  margin-bottom: 8px;
}
