.legend-card {
  padding: 0 10px 10px;
  margin-bottom: 30px;
  border: 2px solid #dadada;
}

.legend-card__header {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px;
}

.legend-card__header span:first-child {
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
  color: #777;
}

.legend-card__header span:last-child {
  font-size: 12px;
  color: #989898;
}
