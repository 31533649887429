.onboarding .ant-steps-item-process .ant-steps-item-icon {
  background: var(--purple);
  border: none;
}

.onboarding .ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--purple);
}

.onboarding .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--purple);
}

.onboarding
  .ant-steps-item-finish
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: var(--purple);
}

.onboarding__content {
  padding: 15px 0 30px 0;
}

.onboarding__footer {
  display: flex;
  justify-content: space-between;
}
