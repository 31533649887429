.floatRight {
  float: right;
}

.header {
  background: #fff;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.container {
  padding: 10px;
}

.navigationBar {
  background-color: #fafafa;
  border: 0.3px solid black;
  width: max-content;
  padding: 10px;
  margin: 0 0 0 25px;
  height: min-content;
}

.infoContainer {
  background-color: #fafafa;
  border: 0.3px solid black;
  padding: 10px;
  max-width: max-content;
  margin-bottom: 10px;
}

.newCultureBtn {
  margin: 20px 0 20px 0;
}

.ActionCultureBtn {
  margin: 0 2px 0 2px;
}

.subTitle {
  font-size: large;
}
