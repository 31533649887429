.pesquisas__header {
  background: #fff;
  padding: 15px 30px;
  border-bottom: 1px solid #ebedf0;
}

.pesquisas__header__title {
  color: black;
  font-size: 24px;
}

.pesquisas__content {
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  justify-items: center;
  row-gap: 20px;
}

.pesquisas__content__card {
  width: 360px;
}

.pesquisas__content__avatar {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}
