.cadastro .ant-modal-body {
  padding: 40px;
}

.cadastro__header {
  text-align: center;
}

.cadastro__header h2 {
  font-weight: 500;
  font-size: 24px;
}
