.candidatos__table {
  overflow-y: auto;
}

.candidatos__table__buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.candidatos__table__buttons .btn_gerar_pesquisa {
  margin-right: auto;
}

.candidatos__table .ant-table-title {
  border: none !important;
  padding: 16px 0 !important;
}

.candidatos__table tr > th,
.candidatos__table td.column-acoes,
.candidatos__table td.column-cargo {
  text-align: center;
}

.candidatos__table td.column-social div {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.candidatos__table td.column-social div span + span {
  margin-left: 8px;
}

.candidatos__table td.column-social div span {
  height: 24px;
  width: 24px;
}

.candidatos__table td.column-social div svg {
  height: 24px;
  width: 24px;
}

.candidatos__table__buttons .button:last-child {
  margin-left: 18px;
}

.candidatos__table .ant-table-body {
  overflow-x: auto !important;
}

.candidatos__table__buttons__desktop.ant-btn {
  display: none;
}

@media (max-width: 400px) {
  .candidatos__table__buttons {
    flex-direction: column;
  }

  .candidatos__table__buttons .candidatos__table__button:first-child {
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .candidatos__table__buttons__desktop.ant-btn {
    display: block;
  }

  .candidatos__table__buttons__mobile.ant-btn {
    display: none;
  }
}

.candidatos.ant-card {
  margin-bottom: 40px;
}

.candidatos__buttons {
  display: flex;
  justify-content: space-between;
}

.candidatos__addmodal__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.candidatos__addmodal__footer .candidatos__button__cancel {
  margin-right: auto;
}
