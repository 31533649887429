.button.orange.ant-btn-primary,
.button.orange.ant-btn-primary:hover,
.button.orange.ant-btn-primary:focus,
.button.orange.ant-btn-primary:active,
.button.orange.ant-btn-primary.active {
  color: #ffffff;
  background-color: var(--orange);
  border: 0;
}

.button.orange.ant-btn-link,
.button.orange.ant-btn-link:hover,
.button.orange.ant-btn-link:focus,
.button.orange.ant-btn-link:active,
.button.orange.ant-btn-link.active {
  color: var(--orange);
  border-color: var(--orange);
}

.button.ant-btn-primary.btn_orange,
.button.ant-btn-primary.btn_orange:hover,
.button.ant-btn-primary.btn_orange:focus,
.button.ant-btn-primary.btn_orange:active,
.button.ant-btn-primary.btn_orange.active {
  color: #ffffff;
  background-color: var(--orange);
  border: 0;
}

.button.ant-btn.btn_purple,
.button.ant-btn.btn_purple:hover,
.button.ant-btn.btn_purple:focus,
.button.ant-btn.btn_purple:active,
.button.ant-btn.btn_purple.active {
  color: var(--purple);
  border-color: var(--purple);
}

.button.ant-btn-primary.btn_purple,
.button.ant-btn-primary.btn_purple:hover,
.button.ant-btn-primary.btn_purple:focus,
.button.ant-btn-primary.btn_purple:active,
.button.ant-btn-primary.btn_purple.active {
  color: #ffffff;
  background-color: var(--purple);
  border: 0;
}
