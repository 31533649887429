.cargos__table__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.cargos__table .ant-table-body {
  overflow-x: auto;
}

.cargos__table .ant-table-title {
  border: none !important;
  padding: 16px 0 !important;
}

.cargos__table tr > th,
.cargos__table td.column-acoes {
  text-align: center;
}

@media (max-width: 400px) {
  .cargos__table__buttons {
    flex-direction: column;
  }

  .cargos__table__buttons .cargos__table__button:first-child {
    margin-bottom: 15px;
  }
}

.cargos__card .ant-card-body {
  padding: 30px 40px;
}

.cargos__card .ant-card-head {
  padding-right: 40px;
  padding-left: 40px;
}
