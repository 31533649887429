.login-colaborador .ant-modal-body {
  padding: 40px;
}

.login-colaborador__header {
  text-align: center;
  margin-bottom: 35px;
}

.login-colaborador__header img {
  max-width: 325px;
  width: 100%;
}

.login-colaborador__header h2 {
  font-weight: 500;
  font-size: 24px;
}

.login-colaborador__form__title {
  text-align: center;
  color: var(--orange);
}

.login-colaborador__form__footer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.login-colaborador__form__footer__link-btn.ant-btn-link,
.login-colaborador__form__footer__link-btn.ant-btn-link:hover,
.login-colaborador__form__footer__link-btn.ant-btn-link:focus,
.login-colaborador__form__link-btn.ant-btn-link,
.login-colaborador__form__link-btn.ant-btn-link:hover,
.login-colaborador__form__link-btn.ant-btn-link:focus {
  color: var(--purple);
  padding: 0;
}

.login-colaborador__form__link-btn {
  margin-top: 10px;
}
