.objetivos-estrategicos__gestores__table__buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.objetivos-estrategicos__gestores__table__buttons button + button {
  margin-left: 16px;
}

@media (max-width: 768px) {
  .objetivos-estrategicos__gestores__table__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .objetivos-estrategicos__gestores__table__buttons button + button {
    margin: 0;
  }

  .objetivos-estrategicos__gestores__table__buttons button {
    width: 100%;
  }
}

/*
@media (min-width: 768px) {
	.objetivos-estrategicos__gestores__table__buttons__desktop.ant-btn {
		display: block;
	}

	.objetivos-estrategicos__gestores__table__buttons__mobile.ant-btn {
		display: none;
	}
}
*/

.objetivos-estrategicos__gestores.ant-card {
  margin-bottom: 40px;
}

.objetivos-estrategicos__gestores__buttons {
  display: flex;
  justify-content: space-between;
}

.objetivos-estrategicos__forcas-estrategicas.ant-card {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .objetivos-estrategicos__forcas-estrategicas.ant-card {
    margin-bottom: 0;
  }
}

.objetivos-estrategicos__modelo-gestao__title {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 8px;
}

.objetivos-estrategicos__modelo-gesta__container {
  display: flex;
  flex-direction: column;
}

.objetivos-estrategicos__objetivos__table__button {
  margin-bottom: 15px;
}

.objetivos-estrategicos__card .ant-card-head-title span {
  font-size: 16px;
}

.objetivos-estrategicos__card .ant-card-extra span {
  font-size: 16px;
}

.objetivos-estrategicos__card .ant-card-extra .consensuar-cultura__span {
  color: var(--purple);
  font-weight: 500;
  cursor: pointer;
}

.objetivos-estrategicos__card .ant-card-extra .consensuar-cultura__span:hover {
  border-bottom: 1px solid var(--purple);
}
