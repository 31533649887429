.email-gestor__title {
  font-size: 16px;
  font-weight: 500;
  color: var(--purple);
}

.email-gestor__card.ant-card {
  margin: 16px 0 0 0;
}

.email-gestor__card__item {
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.email-gestor__card__item > div {
  width: 450px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.email-gestor__card__item__title {
  margin-right: 15px;
  text-align: end;
}

.email-gestor__card__item__rate {
  min-width: 150px;
}

.email-gestor__card__item__rate__stars.ant-rate {
  color: var(--orange);
}

.email-gestor__card__item__rate__tooltip {
  font-weight: normal;
  width: 75px;
  display: inline-block;
}

.email-gestor__content__steps {
  margin-top: 16px;
}
.email-gestor__content__steps .ant-steps-item-process .ant-steps-item-icon {
  background: #ef5472;
  border-color: #ef5472;
}

.email-gestor__card
  .email-gestor__questao__container
  + .email-gestor__questao__container {
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.email-gestor__questao__container {
  padding-bottom: 24px;
}

.email-gestor__questao__container p {
  margin: 0;
}

@media screen and (max-width: 991px) {
  .email-gestor__slider {
    margin-top: 16px;
  }
}

.email-gestor__slider:hover .ant-slider-track {
  background-color: var(--orange);
}

.email-gestor__slider .ant-slider-track {
  background-color: var(--purple);
  height: 8px;
}

.email-gestor__slider .ant-slider-rail {
  height: 8px;
}

.email-gestor__slider .ant-slider-step {
  height: 8px;
}
.email-gestor__slider .ant-slider-dot {
  height: 16px;
  width: 16px;
  top: -4px;
}

.email-gestor__slider .ant-slider-handle {
  height: 16px;
  width: 16px;
  margin-top: -4px;
  margin-left: 4px;
}

.email-gestor__slider .ant-slider-mark .ant-slider-mark-text {
  word-break: normal;
  max-width: 40px;
}

.email-gestor__buttons__container > button {
  margin-left: 16px;
}
