.topbar {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.topbar.ant-layout-header {
  background: #3c444e;
}

.topbar__logo img {
  height: 60px;
}
