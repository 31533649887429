.valores-organizacionais__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.valores-organizacionais__cards.ant-card {
  margin-bottom: 30px;
}

.valores-organizacionais__cards .ant-card-body {
  height: 375px;
}

.valores-organizacionais .ant-card-head-title {
  white-space: unset;
}

.valores-organizacionais__cards__title {
  font-weight: 500;
  color: var(--purple);
  margin-bottom: 25px !important;
}

.valores-organizacionais__cards.disabled h6,
.valores-organizacionais__cards.disabled p {
  color: rgba(0, 0, 0, 0.35);
}

.valores-organizacionais__cards__actions.ant-btn-link:not(:disabled),
.valores-organizacionais__cards__actions.ant-btn-link:not(:disabled):hover,
.valores-organizacionais__cards__actions.ant-btn-link:not(:disabled):focus {
  color: var(--purple);
  padding: 0;
}

.valores-org__custom-list {
  max-height: 250px;
  overflow-y: auto;
}

.valoresorg__modal {
  width: 95% !important;
}

@media screen and (min-width: 576px) {
  .valoresorg__modal {
    width: 500px !important;
  }
}

@media screen and (min-width: 768px) {
  .valoresorg__modal {
    width: 600px !important;
  }
}

@media screen and (min-width: 992px) {
  .valoresorg__modal {
    width: 700px !important;
  }
}

@media screen and (min-width: 1200px) {
  .valoresorg__modal {
    width: 800px !important;
  }
}

.valoresorg-comportamento__options_containter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0px 16px;
}

.valoresorg-comportamento__options_containter > * + * {
  margin-left: 16px !important;
}

.valoresorg-comportamento__ponderacao__container > * + * {
  margin-left: 8px !important;
}

.valoresorg-comportamento__ponderacao__container > span {
  font-weight: 500;
  font-size: 15px;
}
