.page-header {
  background: #fff;
  padding: 8px 30px 8px;
  border-bottom: 1px solid #ebedf0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page-header--with-tabs {
  border-bottom: none;
}

.page-header__title {
  color: black;
  font-size: 18px;
  font-weight: 500 !important;
}

.page-header__tabs .ant-tabs-nav-container {
  padding: 0 30px;
}

.page-header__tabs .ant-tabs-bar {
  margin: 0;
  background: #fff;
}

.page-header__subtitle {
  font-size: 14px;
  margin: 0;
}

.page-header__left--render-right {
  align-self: center;
}

.page-header__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-start;
}

.page-header__breadcrumb--render-right {
  margin-bottom: 20px;
}
