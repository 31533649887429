.colaboradores-inativos__table__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.colaboradores-inativos__table__buttons
  .colaboradores-inativos__table__buttons__container {
  justify-content: space-between;
}

.colaboradores-inativos__table__buttons .button:last-child {
  margin-left: 18px;
}

.colaboradores-inativos__table__buttons__desktop.ant-btn {
  display: none;
}

@media (min-width: 768px) {
  .colaboradores-inativos__table__buttons__desktop.ant-btn {
    display: block;
  }

  .colaboradores-inativos__table__buttons__mobile.ant-btn {
    display: none;
  }
}

@media (max-width: 768px) {
  .colaboradores-inativos__table__buttons__container {
    width: 100%;
    margin-top: 8px;
  }

  .colaboradores-inativos__table__buttons .button:last-child {
    margin-left: 0px;
  }

  .colaboradores-inativos__table__buttons .button {
    width: 100%;
  }

  .button + .button {
    margin-top: 8px;
  }
}

.colaboradores-inativos.ant-card {
  margin-bottom: 40px;
}

.colaboradores-inativos__buttons {
  display: flex;
  justify-content: space-between;
}

.colaboradores-inativos__footer {
  margin-bottom: 16px;
}

.colaboradores-inativos__action__buttons {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
}
