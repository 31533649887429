.percepcao-ambiente {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  width: 100vw;
}

.percepcao-ambiente__header {
  background: #fff;
  padding: 15px 30px;
  border-bottom: 1px solid #ebedf0;
}

.percepcao-ambiente__header__title {
  color: black;
  font-size: 24px;
}

.percepcao-ambiente__content {
  margin: 30px 0;
  padding: 20px 25px;
}

.percepcao-ambiente__footer {
  display: flex;
  justify-content: space-between;
}

.percepcao-ambiente__content__steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background: #ef5472;
  border-color: #ef5472;
}

.percepcao-ambiente__content__steps
  .ant-steps-item-finish
  .ant-steps-item-icon {
  border-color: #ef5472;
}

.percepcao-ambiente__content__steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #ef5472;
}

.percepcao-ambiente__content__steps
  .ant-steps-item-finish
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: #ef5472;
}

.percepcao-ambiente__content__option {
  margin: 5px;
}

.percepcao-ambiente__content__grade {
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.percepcao-ambiente__content__grade__slider {
  position: relative;
  padding: 0px 30px;
}

.percepcao-ambiente__content__grade__slider .anticon {
  position: absolute;
  margin-top: 14px;
  top: -2px;
  width: 16px;
  height: 16px;
  line-height: 1;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.25);
}

.percepcao-ambiente__content__grade__slider .anticon:first-child {
  left: 0;
}

.percepcao-ambiente__content__grade__slider .anticon:last-child {
  right: 0;
}

.ant-slider-rail {
  background-color: rgba(0, 0, 0, 0.25);
}

.ant-slider-dot {
  background: rgba(0, 0, 0, 0.85);
}

.ant-slider-dot-active {
  color: #fff;
  background: #30318b;
}

.percepcao-ambiente__modal {
  text-align: center;
}

.percepcao-ambiente__modal__image {
  margin: 30px 0;
  width: 100%;
}

.percepcao-ambiente__modal h1 {
  color: #585858;
}

.percepcao-ambiente__modal button {
  margin: 30px 0;
}

.legend-card .ant-alert {
  margin: 6px 0 12px 0;
}
