.times-detalhes-body__card .ant-card-body {
  padding: 30px 40px;
}

.times-detalhes-body__card .ant-card-head {
  padding-right: 40px;
  padding-left: 40px;
}

.times-detalhes__table .ant-table-body {
  overflow-x: auto;
}

.times-detalhes__table .ant-table-title {
  border: none !important;
  padding: 0 0 16px 0 !important;
}

.times-detalhes__table tr > th,
.times-detalhes__table td.data-center {
  text-align: center;
}

.times-detalhes-statistic {
  display: flex;
  flex-direction: row;
}

.times-detalhes-statistic__item {
  padding: 0 24px;
  text-align: end;
}

.times-detalhes-statistic__divider {
  height: 50px;
}

.times-detalhes__tabs {
  background-color: #fff;
}
