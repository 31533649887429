.adicionar-colaboradores > .ant-row:not(:first-child) {
  margin-top: 10px;
}

.adicionar-colaboradores__card {
  margin-bottom: 25px;
}

.adicionar-colaboradores__card__row-btn {
  text-align: center;
}
