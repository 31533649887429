.infografico--pdf__card {
  margin-bottom: 10px;
}

.infografico--pdf__card--selected {
  background-color: var(--purple);
  color: #fff;
}

.infografico--pdf__card__title-caracteristicas {
  font-size: 20px;
  font-weight: lighter;
}

.infografico--pdf__card--caracteristicas {
  text-align: center;
  display: flex;
  justify-content: center;
}

.infografico--pdf__card--caracteristicas .ant-card-body {
  padding: 5px 24px;
}

.infografico--pdf__card__table {
  margin-bottom: 20px;
}

.infografico--pdf__donut-title {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  min-height: 60px;
  margin: 0;
  margin-bottom: -40px;
}

.infografico--pdf__donut-center__desc {
  color: #8c8c8c;
  font-size: 18px;
  text-align: center;
}

.infografico--pdf__donut-center__total {
  color: #262626;
  font-size: 28px;
}

.infografico--pdf__statistic .ant-statistic-content {
  font-size: 32px;
}

.infografico--pdf__container-table {
  display: flex;
  justify-content: center;
  margin: 24px auto;
}

.infografico--pdf__table {
  margin: 0;
  font-size: 16px;
}

.infografico--pdf__table th,
.infografico--pdf__table td {
  text-align: center;
  padding: 8px;
}

.infografico--pdf__statistic__title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.infografico--pdf__statistic__score-container {
  margin-top: 0;
}

.infografico--pdf__statistic__score-content {
  align-items: center;
  min-height: 80px;
}

.infografico--pdf__statistic__score {
  color: #000;
  font-size: 32px;
}

.infografico--pdf__statistic__icon {
  font-size: 2.5rem;
  color: rgba(49, 49, 139, 0.7);
}

.infografico--pdf__statistic__icon--left {
  padding-left: 20px;
}

.infografico--pdf__statistic__icon--right {
  padding-right: 20px;
}
