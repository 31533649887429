.modal-pesquisa-comportamental__footer {
  display: flex;
  justify-content: space-between;
}

.modal-pesquisa-comportamental > h3 {
  color: rgba(0, 0, 0, 0.65);
  margin-top: 24px;
}

.modal-pesquisa-comportamental__card {
  width: 100%;
  border: 1px solid #bfbfbf;
  padding: 10px;
}

.modal-pesquisa-comportamental__card h3 {
  color: #ef5472;
  text-align: center;
}

.modal-pesquisa-comportamental__card__steps {
  margin-top: 30px;
}

.modal-pesquisa-comportamental__card__steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background: #ef5472;
  border-color: #ef5472;
}
