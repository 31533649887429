.perfil-comportamental {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.perfil-comportamental__header {
  background: #fff;
  padding: 15px 30px;
  border-bottom: 1px solid #ebedf0;
}

.perfil-comportamental__header__title {
  color: black;
  font-size: 24px;
}

.perfil-comportamental__content {
  margin: 30px 0;
  padding: 20px 25px;
}

.perfil-comportamental__content__card {
  padding: 15px 0 30px 0;
}

.perfil-comportamental__content__card__checkbox {
  margin: 7px 0;
}

.perfil-comportamental__content__card__popover {
  max-width: 240px;
}

.perfil-comportamental__footer {
  display: flex;
  justify-content: space-between;
}

.perfil-comportamental__content__steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background: #ef5472;
  border-color: #ef5472;
}

.perfil-comportamental__content__steps
  .ant-steps-item-finish
  .ant-steps-item-icon {
  border-color: #ef5472;
}

.perfil-comportamental__content__steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #ef5472;
}

.perfil-comportamental__content__steps
  .ant-steps-item-finish
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: #ef5472;
}

.perfil-comportamental__modal {
  text-align: center;
}

.perfil-comportamental__modal__image {
  margin: 30px 0;
  width: 100%;
}

.perfil-comportamental__modal h1 {
  color: #585858;
}

.perfil-comportamental__modal button {
  margin: 30px 0;
}

.perfil-comportamental__content__row {
  flex: 1;
}
