.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.editable-table .ant-table-body {
  overflow-x: auto !important;
}

.editable-table .ant-table-empty {
  margin-bottom: 16px;
}

.editable-table .action {
  color: var(--purple);
  display: inline-block;
  text-decoration: none;
}

.editable-table .action:hover {
  text-decoration: underline;
  cursor: pointer;
}
