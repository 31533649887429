.content-layout__body {
  padding: 8px 16px 8px 16px;
}

.fixed-content-layout {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  justify-content: flex-start;
  align-items: stretch;
}

.fixed-content-layout__body {
  min-height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0px !important;
}
