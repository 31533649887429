.list-membros .ant-list-item {
  border: none;
}

.list-membros .ant-list-items {
  height: 200px;
  overflow-y: auto;
  border-bottom: 1px solid #e8e8e8;
}

.visao-individual__table .ant-table-body {
  overflow-x: auto;
}

.visao-individual__card {
  margin-bottom: 16px;
  min-height: 411px;
}

.visao-individual__card-statistic .infografico__card {
  margin-bottom: 16px;
}

.list-membros
  .ant-spin-container
  > .ant-list-items
  > .ant-list-item:last-child {
  border: none;
}

.row-button-list {
  margin-top: 8px;
}

.row-button-list button {
  width: 100%;
}

.dashboard__card-forcas {
  height: 516px;
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .dashboard__card-forcas {
    height: auto;
  }
}

.visao-individual__card--selected {
  background-color: var(--purple);
  color: #fff;
}

.visao-individual__card__title-caracteristicas {
  font-size: 20px;
  font-weight: lighter;
}

.visao-individual__card--caracteristicas {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.visao-individual__card--caracteristicas .ant-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.visao-individual__donut-title {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  min-height: 60px;
  margin: 0;
  margin-bottom: -40px;
}

.visao-individual__donut-center__desc {
  color: #8c8c8c;
  font-size: 18px;
  text-align: center;
}

.visao-individual__donut-center__total {
  color: #262626;
  font-size: 28px;
}

.visao-individual__statistic .ant-statistic-content {
  font-size: 32px;
}

.visao-individual__table {
  margin: 0;
  font-size: 16px;
}

.visao-individual__table th,
.visao-individual__table td {
  text-align: center;
  padding: 8px;
}

.visao-individual__statistic__title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.visao-individual__statistic__score-container {
  margin-top: 24px;
}

.visao-individual__statistic__score-content {
  align-items: center;
  min-height: 80px;
}

.visao-individual__statistic__score {
  color: #000;
  font-size: 32px;
}

.visao-individual__statistic__icon {
  font-size: 2.5rem;
  color: rgba(49, 49, 139, 0.7);
}

.visao-individual__statistic__icon--left {
  padding-left: 20px;
}

.visao-individual__statistic__icon--right {
  padding-right: 20px;
}

.no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.clearfix {
  content: ' ' !important;
  display: block !important;
  clear: both !important;
}

.divToPrint {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: -1 !important;
  padding: 10px !important;
}

.html2canvas-container {
  width: 10000px !important;
  height: 10000px !important;
}
