.adicionar-time > .ant-row:not(:first-child) {
  margin-top: 10px;
}

.adicionar-time__card {
  margin-bottom: 25px;
}

.adicionar-time__card__row-btn {
  text-align: center;
}
