.select {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.select.remove-margin {
  margin-bottom: 0;
}

.select__label {
  margin-bottom: 8px;
}
