.dashboard-layout {
  min-height: 100vh;
}

.dashboard-layout__side-menu {
  -webkit-box-shadow: 2px 0px 6px 0px rgb(0, 21, 41, 0.35);
  -moz-box-shadow: 2px 0px 6px 0px rgb(0, 21, 41, 0.35);
  box-shadow: 2px 0px 6px 0px rgb(0, 21, 41, 0.35);
  z-index: 1;
}

.dashboard-layout__side-menu__logo {
  padding: 10px 0;
  text-align: center;
  background-color: #002140;
}

.dashboard-layout__side-menu__logo img {
  height: 40px;
}

.dashboard-layout__header {
  background-color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: space-between;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.1);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.1);
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.1);
  z-index: 0;
  height: 48px;
}

.dashboard-layout__header__btn {
  font-size: 18px;
  line-height: 48px;
  padding: 0 15px;
  cursor: pointer;
  transition: color 0.3s;
}

.dashboard-layout__header__btn span {
  display: flex;
  align-items: center;
}

.dashboard-layout__header__btn:hover {
  color: #1890ff;
}

.dashboard-layout__header__profile {
  margin: 0 24px;
  display: flex;
  align-items: center;
}

.dashboard-layout__header__profile__avatar {
  margin: 0 8px;
}

.dashboard-layout__header__dropdown {
  line-height: 48px;
  display: flex;
  align-items: center;
}

.dashboard-layout__content {
  background: #f0f2f5;
}

.dashboard-layout__content__inner {
  height: 100%;
  overflow-x: hidden;
}

.fixed-layout {
  max-height: 100vh;
}

.fixed-layout .dashboard-layout__content {
  max-height: 100%;
}

.fixed-layout .dashboard-layout__content__inner {
  max-height: 100%;
}
