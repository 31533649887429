.gestao-pesquisas__table__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.gestao-pesquisas__table__button {
  margin-right: 18px;
}

.gestao-pesquisas__table .ant-table-body {
  overflow-x: auto;
}

.gestao-pesquisas__table .ant-table-title {
  border: none !important;
  padding: 16px 0 !important;
}

.gestao-pesquisas__table tr > th,
.gestao-pesquisas__table td {
  text-align: center;
}

@media (max-width: 400px) {
  .gestao-pesquisas__table__buttons {
    flex-direction: column;
  }

  .gestao-pesquisas__table__buttons
    .gestao-pesquisas__table__button:first-child {
    margin-bottom: 15px;
  }
}

.gestao-pesquisas__card .ant-card-head {
  padding-right: 40px;
  padding-left: 40px;
}

.gestao-pesquisas__status {
  display: block;
  width: 6px;
  margin: 0 auto;
  height: 6px;
  border-radius: 50%;
}

.gestao-pesquisas__status--nao-respondido {
  background-color: #db092f;
}

.gestao-pesquisas__status--respondido {
  background-color: #3dd353;
}
