.textual__card {
  width: 100%;
  margin-bottom: 24px;
}

.textual__card__header {
  display: flex;
  align-items: center;
  padding: 0 0 8px;
  min-height: 85px;
}

.textual__card__icon {
  margin-right: 16px;
}

.textual__card__title h1 {
  font-size: 18px;
  margin: 0;
}

.textual__card__body p {
  font-size: 15px;
}

.textual__card__title p {
  font-size: 16px;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .textual__card {
    min-width: auto;
  }
}

.textual__col {
  display: flex;
  align-items: stretch;
}
