.perfil {
  margin: 20px;
}

.perfil__info,
.perfil__data,
.perfil__tabs {
  background-color: #fff;
}

.perfil__tabs {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .perfil__data,
  .perfil__tabs {
    margin-top: 20px;
  }
}

/* Perfil info */
.perfil__info {
  padding: 25px 35px;
}

.perfil__info p,
.perfil__info a {
  word-break: break-all;
  hyphens: manual;
}

.perfil__info p,
.perfil__info a {
  word-break: break-all;
  hyphens: manual;
}

.perfil__info__user {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.perfil__info__user__nome {
  margin-top: 16px;
  font-weight: 500;
}

.perfil__info__user__cargo {
  font-weight: lighter;
  margin-bottom: 40px;
  text-align: center;
}

.perfil__info__user__avatar.desativado,
.perfil__info__user__nome.desativado,
.perfil__info__user__cargo.desativado span:last-child {
  opacity: 0.6;
}

.perfil__info__user__cargo .desativado {
  color: #db092f;
  opacity: 1;
}

.perfil__info__user__dados {
  width: 100%;
}

.perfil__info__user__dados span {
  display: flex;
}

.perfil__info__user__dados > span:last-child {
  justify-content: space-between;
}

.perfil__info__user__dados span i {
  color: rgba(126, 126, 126, 0.85);
  font-size: 18px;
  margin-right: 10px;
}

.perfil__info__tag {
  padding: 5px 15px;
  margin-top: 10px;
}

.perfil__info__tag__lider {
  color: #ef5472;
  margin-right: 8px;
}

.perfil__info__tag--adicionar {
  padding: 5px 10px;
  margin-top: 10px;
  background: #fff;
  border-style: dashed;
}

@media (min-width: 1600px) {
  .perfil__info__login__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 122px;
  }
}

.perfil__info__login__btn--desativar {
  background-color: #db092f;
  border-color: #db092f;
}

.perfil__info__login__btn--desativar:active,
.perfil__info__login__btn--desativar:focus,
.perfil__info__login__btn--desativar:hover {
  background-color: #bd0526;
  border-color: #bd0526;
}

/* Perfil data */
.perfil__data {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}

.perfil__data__item {
  padding: 0 10px;
  text-align: center;
}

.perfil__data__item__divider {
  height: auto;
}

/* Perfil tabs */
.perfil__tabs__home,
.perfil__tabs__pesquisas,
.perfil__tabs__times-cargos {
  padding: 35px;
}

.perfil__tabs__pesquisas__table__title,
.perfil__tabs__times-cargos__table__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
