.expand-row-icon {
  cursor: pointer;
  margin-right: 10px;
  color: #000;
}

.times__table table {
  border: 1px solid #d9d9d9;
}

th.column-times,
th.column-membros,
th.column-opcoes,
td.column-membros,
th.column-grupo-pai,
td.column-opcoes {
  text-align: center !important;
}

.times__table__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.times__table .ant-table-body {
  overflow-x: auto !important;
}

@media (max-width: 400px) {
  .times__table__buttons {
    flex-direction: column;
  }

  .times__table__buttons .times__table__button:first-child {
    margin-bottom: 15px;
  }
}

.times__card .ant-card-body {
  padding: 30px 40px;
}

.times__card .ant-card-head {
  padding-right: 40px;
  padding-left: 40px;
}
